import {observer} from "mobx-react";
import {useState} from "react";
import styles from "./styles/StatementBlockCS.module.scss";
import BlockTitle from "../_BlockTitle";
import {CSSTransition} from "react-transition-group";
import dynamic from "next/dynamic";
import {getCdnUrl, reactScrollBarsCustomWidth} from "../../../../utils/SchoolBlocksUtilities";
import {buildCssUrlString} from "../../../../utils/StringUtilities";
import ClickableLink from "../../../utilities/ClickableLink";
import Scrollbar from "react-scrollbars-custom";
import Markdown from "../../../utilities/Markdown";
import {EditDropDownItem} from "../EditDropDown";
import FontAwesome from "../../../utilities/FontAwesome";
import classNames from 'classnames';

const StatementBlockEditor = dynamic(() => import("./StatementBlockEditor"));

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};
const StatementBlock = observer((props: {
    blockObj: IStatementTerrace
    aboveTheFold: boolean,
}) => {

    const [editMode, setEditMode] = useState(false);

    const mediaObject = props.blockObj.json_data.statement?.media;
    const mediaObjectUrl: string = mediaObject?.url || "";
    const imgSrcUrl = buildCssUrlString(getCdnUrl(mediaObjectUrl));

    const statementBlockEditorDropDownItems: EditDropDownItem[] = [
        {
            title: 'Edit',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-pencil-alt"/>,
            onClick: () => setEditMode(v => !v),
        },
    ]

    const messageContainerClassName = classNames({
        [styles.messageContainer]: true,
        [styles.messageContainerNoButton]: !props.blockObj.json_data.statement?.buttonData.buttonToggle,
    })

    return (
        <section className={styles.statementContainer}>
            {editMode ? <StatementBlockEditor
                editMode={editMode}
                setEditMode={setEditMode}
                blockObj={props.blockObj}
                statementBlockEditorDropDownItems={statementBlockEditorDropDownItems}
            /> : <>
                <div className={styles.statementBackgroundImage}
                     style={mediaObjectUrl ? {backgroundImage: imgSrcUrl} : {}}/>
                <div className={styles.statementGradient}>
                    <BlockTitle blockObj={props.blockObj}
                                editing={editMode}
                                setEditing={setEditMode}
                                aboveTheFold={props.aboveTheFold}
                                blockTitleContainerClassName={styles.blockTitleContainerClassName}
                                dropDownItems={statementBlockEditorDropDownItems}
                                editDropDownClassName={styles.editDropDown}
                                showViewMore={false}
                    />
                    <div className={styles.messageTransitionContainer}>
                        <CSSTransition appear classNames={transitionClassNames} in={props.aboveTheFold}
                                       timeout={500}>
                            <div className={messageContainerClassName}>
                                <div className={styles.contentContainer}>
                                    <Scrollbar
                                        scrollbarWidth={reactScrollBarsCustomWidth}
                                        disableTrackYWidthCompensation={true}
                                        wrapperProps={{
                                            style: {width: "90%"},
                                        }}
                                        thumbYProps={{
                                            style: {background: "white"},
                                        }}
                                        trackYProps={{
                                            style: {width: 6}
                                        }}
                                    >
                                        <Markdown source={props.blockObj.json_data.statement?.text} withStyles={true}/>
                                    </Scrollbar>
                                </div>
                                {props.blockObj.json_data.statement?.buttonData.buttonToggle && <div className={styles.linkButtonContainer}>
                                    <ClickableLink
                                        href={props.blockObj.link_url}
                                        className={styles.callToActionLink}
                                    >
                                        <span>{props.blockObj.json_data.statement?.buttonData.buttonText}</span>
                                    </ClickableLink>
                                </div>}
                                <img src={getCdnUrl(mediaObjectUrl)}
                                     className={styles.statementMobileImage} alt={mediaObject?.alt}/>
                            </div>
                        </CSSTransition>
                    </div>
                </div>
            </>}
        </section>
    );
});

export default StatementBlock;
